import useSWR from "swr";

import { RISK_RATING_OPEN_SEARCH_KEYS } from "@/constants/openSearch";
import { OPEN_SEARCH_CACHE } from "@/constants/session";
import { useOpenSearch } from "@/hooks/useOpenSearch";

export const useRiskRatingsOpenSearchSubHazardType = () => {
  const { openSearch, isEnabled, orgId, body } = useOpenSearch({
    searchKey: "hazard_types",
    field: RISK_RATING_OPEN_SEARCH_KEYS.subHazardTypes,
  });

  const { data, isLoading, error } = useSWR(
    isEnabled ? ["openSearchSubHazardType", orgId] : undefined,
    async () => {
      const subHazardTypes = await openSearch.risk_rating.getData(body, orgId);
      return subHazardTypes.data.aggregations.hazard_types.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchConsequences = () => {
  const { openSearch, orgId, isEnabled, body } = useOpenSearch({
    searchKey: "consequence_types",
    field: RISK_RATING_OPEN_SEARCH_KEYS.consequences,
  });

  const { data, isLoading, error } = useSWR(
    isEnabled ? ["openSearchConsequences", orgId] : undefined,
    async () => {
      const consequences = await openSearch.risk_rating.getData(body, orgId);
      return consequences.data.aggregations.consequence_types.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchClimateScenarios = () => {
  const { openSearch, orgId, isEnabled, body } = useOpenSearch({
    searchKey: "climate_scenarios",
    field: RISK_RATING_OPEN_SEARCH_KEYS.rcpScenario,
  });

  const { data, isLoading, error } = useSWR(
    isEnabled ? ["openSearchClimateScenarios", orgId] : undefined,
    async () => {
      const climateScenarios = await openSearch.risk_rating.getData(
        body,
        orgId
      );
      return climateScenarios.data.aggregations.climate_scenarios.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};

export const useRiskRatingsOpenSearchTimeHorizons = () => {
  const { openSearch, orgId, isEnabled, body } = useOpenSearch({
    searchKey: "time_horizon",
    field: RISK_RATING_OPEN_SEARCH_KEYS.timeHorizon,
  });

  const { data, isLoading, error } = useSWR(
    isEnabled ? ["openSearchTimeHorizon", orgId] : undefined,
    async () => {
      const timeHorizons = await openSearch.risk_rating.getData(body, orgId);
      return timeHorizons.data.aggregations.time_horizon.buckets;
    },
    { dedupingInterval: OPEN_SEARCH_CACHE }
  );

  return { data, isLoading, error };
};
