import { Avatar, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  value: string | number | any;
  testId: string;
}

export const OpenSearchMenuItem = (props: Props) => {
  const { value, testId } = props;
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      spacing={1}
    >
      <Typography>{t(value.key ?? value)}</Typography>
      {value.doc_count && (
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: "#b5b5b5",
            width: value.doc_count.toString().length * 10,
            minWidth: 30,
            height: 20,
            fontSize: "0.8rem",
            fontWeight: 800,
          }}
          data-test={`${testId}-option-${value.key}-count`}
        >
          {value.doc_count}
        </Avatar>
      )}
    </Stack>
  );
};
