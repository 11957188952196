import type { FocusEventHandler } from "react";

import type { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import type { CSSProperties } from "@mui/material/styles/createMixins";
import { useTranslation } from "react-i18next";

import { DefaultLoader } from "@/components/common/loading/DefaultLoader";
import { OpenSearchMenuItem } from "@/components/open-search/OpenSearchMenuItem";

type Value = string | number | any;

interface Props<Value> {
  value?: Value;
  onChange: (e: SelectChangeEvent<any>) => void;
  options: Value;
  label?: string;
  width?: string | number;
  styles?: CSSProperties;
  isLoading?: boolean;
  testId?: string;
  error?: boolean;
  required?: boolean;
  showLabel?: boolean;
  hideIcon?: boolean;
  allOptionsLabel?: string;
  disabled?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const GenerateDropdown = (props: Props<Value>) => {
  const { value, onChange, label, options, onBlur, styles } = props;
  const { showLabel, isLoading, hideIcon, allOptionsLabel } = props;
  const { width = 140, required, disabled, error } = props;
  const { t } = useTranslation();
  const testId = props.testId ?? "filter-select";

  if (isLoading) return <DefaultLoader sx={{ width: width }} height="28px" />;

  return (
    <FormControl size="small" sx={{ width: width }} required={required}>
      {showLabel && <InputLabel>{label}</InputLabel>}
      <Select
        disabled={disabled}
        required={required}
        label={(showLabel && label) ?? ""}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
        sx={styles}
        data-test={testId}
        error={error}
      >
        {allOptionsLabel && (
          <MenuItem value="all">{t(allOptionsLabel)}</MenuItem>
        )}

        {Object.values(options).map((o: any) => (
          <MenuItem
            key={o.key ?? o}
            value={o.key ?? o}
            data-test={`${testId}-option-${o.key ?? o}`}
          >
            <OpenSearchMenuItem value={o} testId={testId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
