import type {
  AssetStatusEnum,
  GroupResponse,
} from "@/apis/services/AssetService";
import type { RatingInfo } from "@/apis/services/HazardService";
import { RiskRatingConsequenceType } from "@/apis/services/HazardService";
import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import type { HazardUnion } from "@/components/asset/filter/HazardFilter";

export const filterGroups = (groupData: GroupResponse[], filter: string) => {
  if (!filter) return groupData;
  return groupData.filter((g) =>
    g.name.toLowerCase().includes(filter.toLowerCase())
  );
};

interface FilterRowOptions {
  status: AssetStatusEnum[];
  filterNotAssessed: boolean;
  selectedHazards: HazardUnion[];
  selectedConsequences: ConsequenceUnion[];
}

export const filterRow = (row: any, options: FilterRowOptions) => {
  const { status, filterNotAssessed, selectedHazards, selectedConsequences } =
    options;

  // if the row has a status (meaning the column is visible) and
  // if the status of the asset is not part of our filter status list we hide the row
  const assetStatus = row.status;
  if (assetStatus && !status.includes(assetStatus)) return false;

  // if the filter on not assessed is NOT selected, always return the row
  if (!filterNotAssessed) return true;

  // if either selected hazards or consequences is empty, there is nothing to filter
  if (selectedHazards.length === 0) return true;
  if (selectedConsequences.length === 0) return true;

  // loop through every hazard / consequence combination and check for a rating
  // if the rating exists, return true because we want to include the row
  return selectedHazards.some((hazard) => {
    return selectedConsequences.some((consequence) => {
      const rating = row[`${hazard}_${consequence}`];
      return !!rating;
    });
  });
};

export const filterOutHazardRating = (rating: RatingInfo) => {
  return rating.consequence !== RiskRatingConsequenceType.HAZARD_RATING;
};
