import type {
  ClimateScenario,
  HazardType,
  RiskClassEnum,
  SubHazardType,
} from "@/apis/services/HazardService";

type OPTIONS_TYPE =
  | "total"
  | "hazard_type"
  | "subHazardType"
  | "risk_class"
  | "climate_scenario"
  | "time_horizon"
  | "assessments";

export type OpenSearchData = { key: number | string; doc_count: number };

export const OPTIONS_TYPE_LOOKUP: { [key: string]: string } = {
  hazard_type: "hazards",
  subHazardType: "subHazardTypes",
  risk_class: "riskClasses",
  climate_scenario: "climateScenarios",
  time_horizon: "timeHorizons",
};

export type OpenSearchOptions = {
  type?: OPTIONS_TYPE;
  hazards?: HazardType[];
  subHazardTypes?: SubHazardType[];
  riskClasses?: RiskClassEnum[];
  climateScenarios?: ClimateScenario[];
  timeHorizons?: number[];
  sort?: { key: "key" | "doc_count" };
  ignoreKey?: string;
  size?: number;
};

const OPEN_SEARCH_KEYS: { [key: string]: string } = {
  hazards: "hazard_type.keyword",
  subHazardTypes: "subhazard_type.keyword",
  consequences: "consequence_type.keyword",
  riskClasses: "risk_class",
  climateScenarios: "climate_scenario.keyword",
  rcpScenario: "rcp_scenario.keyword",
  timeHorizons: "time_horizon",
};

export const RISK_RATING_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardTypes: `${OPEN_SEARCH_KEYS.subHazardTypes}`,
  consequences: `${OPEN_SEARCH_KEYS.consequences}`,
  rcpScenario: `${OPEN_SEARCH_KEYS.rcpScenario}`,
  timeHorizon: `${OPEN_SEARCH_KEYS.timeHorizons}`,
};

export const ASSESSMENT_OPEN_SEARCH_KEYS: { [key: string]: string } = {
  subHazardTypes: `assessment.${OPEN_SEARCH_KEYS.subHazardTypes}`,
  riskClasses: `assessment.${OPEN_SEARCH_KEYS.riskClasses}`,
  climateScenarios: `assessment.${OPEN_SEARCH_KEYS.climateScenarios}`,
  timeHorizons: `assessment.${OPEN_SEARCH_KEYS.timeHorizons}`,
  hazards: `assessment.${OPEN_SEARCH_KEYS.hazards}`,
};
